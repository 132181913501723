import { default as indextyqtJv4xhOMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue?macro=true";
import { default as indexGw0SQQEDCPMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as indexDeKzy3MtvxMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
import { default as indexWzBU9rhfj1Meta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue?macro=true";
import { default as indexeCds1W1XzrMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue?macro=true";
import { default as index7YTjtIvwWZMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue?macro=true";
import { default as indexxahs7DQtIsMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue?macro=true";
import { default as index59Je7D64ALMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue?macro=true";
import { default as index17Qef3NFy1Meta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue?macro=true";
import { default as indexU80zxlTgHkMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue?macro=true";
import { default as indexV7yuasoGicMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue?macro=true";
import { default as _91checkFileId_93Y6FWdshSbvMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue?macro=true";
import { default as indexCU5JGD4LVoMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/index.vue?macro=true";
import { default as selectj9qm1KLhrUMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/operators/select.vue?macro=true";
import { default as indexXjUu5JcsPXMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue?macro=true";
import { default as indexqPb79CjtHIMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/index.vue?macro=true";
import { default as indexgnO3Yc8o6bMeta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/index.vue?macro=true";
import { default as loginSSscZdmcd5Meta } from "/home/runner/work/checkup/checkup/apps/operator/src/pages/login.vue?macro=true";
export default [
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()",
    meta: indextyqtJv4xhOMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: indexGw0SQQEDCPMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: indexDeKzy3MtvxMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-yyyymmdd-todo",
    path: "/:organizationId()/checkFiles/:checkFileId()/:yyyymmdd()/todo",
    meta: indexWzBU9rhfj1Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/[yyyymmdd]/todo/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets-ticketId",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets/:ticketId()",
    meta: indexeCds1W1XzrMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-tickets",
    path: "/:organizationId()/checkFiles/:checkFileId()/tickets",
    meta: index7YTjtIvwWZMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/tickets/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyy-mm",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyy()/:mm()",
    meta: indexxahs7DQtIsMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyy]/[mm]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-preview",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/preview",
    meta: index59Je7D64ALMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/preview/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()",
    meta: index17Qef3NFy1Meta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes-timeboxId-workSheets-workSheetId",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes/:timeboxId()/workSheets/:workSheetId()",
    meta: indexU80zxlTgHkMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/[timeboxId]/workSheets/[workSheetId]/index.vue")
  },
  {
    name: "organizationId-checkFiles-checkFileId-workFiles-yyyymmdd-timeboxes",
    path: "/:organizationId()/checkFiles/:checkFileId()/workFiles/:yyyymmdd()/timeboxes",
    meta: indexV7yuasoGicMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/[checkFileId]/workFiles/[yyyymmdd]/timeboxes/index.vue")
  },
  {
    name: "organizationId-checkFiles-select-checkFileId",
    path: "/:organizationId()/checkFiles/select/:checkFileId()",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/[checkFileId].vue")
  },
  {
    name: "organizationId-checkFiles-select",
    path: "/:organizationId()/checkFiles/select",
    meta: indexCU5JGD4LVoMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/checkFiles/select/index.vue")
  },
  {
    name: "organizationId-operators-select",
    path: "/:organizationId()/operators/select",
    meta: selectj9qm1KLhrUMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/operators/select.vue")
  },
  {
    name: "organizationId-tickets-ticketId",
    path: "/:organizationId()/tickets/:ticketId()",
    meta: indexXjUu5JcsPXMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/[ticketId]/index.vue")
  },
  {
    name: "organizationId-tickets",
    path: "/:organizationId()/tickets",
    meta: indexqPb79CjtHIMeta || {},
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/[organizationId]/tickets/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/checkup/checkup/apps/operator/src/pages/login.vue")
  }
]