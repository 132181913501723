import revive_payload_client_XdH2Tdg4Wn from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qsDQdPCyU1 from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_csb0OhcGHP from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_NH0GwAs195 from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GcGoI5rp9S from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Emq3m70Vnc from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_6WSxqC1sHj from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/apps/operator/.nuxt/components.plugin.mjs";
import prefetch_client_HHJNWKHjVq from "/home/runner/work/checkup/checkup/apps/operator/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.13_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__jiyy5ce7e5sbapam556gqczrbq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/sentry.client.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/apps/operator/src/plugins/i18n.ts";
export default [
  revive_payload_client_XdH2Tdg4Wn,
  unhead_qsDQdPCyU1,
  router_csb0OhcGHP,
  payload_client_NH0GwAs195,
  navigation_repaint_client_GcGoI5rp9S,
  check_outdated_build_client_Emq3m70Vnc,
  chunk_reload_client_6WSxqC1sHj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HHJNWKHjVq,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  i18n_YCWKwfVXF6
]