import type { CheckFile } from '~/entities/checkFile'
import type { DailyWorkFile, DailyWorkFileCreateParams } from '~/entities/dailyWorkFile'
import type { WorkItem } from '~/entities/workItem'
import type { WorkSheet } from '~/entities/workSheet'
import type { Operator } from '~/entities/operator'
import { InvalidStatusError } from '~/repositories/error'

export const getDailyWorkFile = async (checkFileId: CheckFile['id'], targetDate: Dayjs) => {
  const { data, error } = await useSupabaseClient()
    .from('daily_work_files')
    .select('*')
    .eq('check_file_id', checkFileId)
    .eq('work_date', formatDate(targetDate))
    .maybeSingle()
  if (error) throw error
  return data
}

export const getDailyWorkFilesByCheckFileIds = async (
  checkFileIds: CheckFile['id'][],
  targetDate: Dayjs
) => {
  const { data, error } = await useSupabaseClient()
    .from('daily_work_files')
    .select('*')
    .in('check_file_id', checkFileIds)
    .eq('work_date', formatDate(targetDate))
  if (error) throw error
  return data
}

export const getDailyWorkFileWithAllRelations = async (
  checkFileId: CheckFile['id'],
  targetDate: Dayjs,
  timeboxId?: WorkItem['timebox_id'],
  workSheetId?: WorkSheet['id']
) => {
  let query = useSupabaseClient()
    .from('daily_work_files')
    .select(
      '*, work_daily_verifiers(*), work_sheets!inner(*, work_items!inner(*, notes(*), tickets(*), work_number_conditions(*)))'
    )
    .eq('check_file_id', checkFileId)
    .eq('work_date', formatDate(targetDate))
    .order('check_sheet_assignment_position', { referencedTable: 'work_sheets', ascending: true })
  if (timeboxId) query = query.eq('work_sheets.work_items.timebox_id', timeboxId)
  if (timeboxId === null) query = query.is('work_sheets.work_items.timebox_id', null)
  if (workSheetId) query = query.eq('work_sheets.id', workSheetId)

  const { data, error } = await query.maybeSingle()
  if (error) throw error

  return data
}

export const createDailyWorkFile = async (params: DailyWorkFileCreateParams) => {
  const { data, error } = await useSupabaseClient()
    .from('daily_work_files')
    .insert(params)
    .select()
    .single()
  if (error) throw error
  return data
}

export const deleteDailyWorkFileByCheckFileId = async (
  checkFileId: CheckFile['id'],
  targetDate: Dayjs
) => {
  const client = useSupabaseClient()

  const { data, error: selectError } = await client
    .from('daily_work_files')
    .select()
    .eq('check_file_id', checkFileId)
    .eq('work_date', formatDate(targetDate))
    .maybeSingle()

  if (selectError) throw selectError

  // すでに存在しなければ何もしない
  if (!data) return

  if (data.status === 'SUBMITTED') throw new InvalidStatusError()

  const { error } = await useSupabaseClient()
    .from('daily_work_files')
    .delete()
    .eq('check_file_id', checkFileId)
    .eq('work_date', formatDate(targetDate))
  if (error) throw error
}

export const deleteDailyWorkFile = async (workFileId: DailyWorkFile['id']) => {
  const client = useSupabaseClient()

  const { data, error: selectError } = await client
    .from('daily_work_files')
    .select()
    .eq('id', workFileId)
    .maybeSingle()

  if (selectError) throw selectError

  // すでに存在しなければ何もしない
  if (!data) return

  if (data.status === 'SUBMITTED') {
    throw new InvalidStatusError()
  }

  const { error } = await client.from('daily_work_files').delete().eq('id', workFileId)
  if (error) throw error
}

export const submitDailyWorkFile = async (
  checkFileId: CheckFile['id'],
  targetDate: Dayjs,
  submittedOperator: Operator
) => {
  const { error } = await useSupabaseClient()
    .from('daily_work_files')
    .update({
      status: 'SUBMITTED',
      submitted_operator_id: submittedOperator.id,
      submitted_operator_name: submittedOperator.name,
      submitted_operator_name_pronunciation: submittedOperator.name_pronunciation,
      submitted_at: currentDate().toISOString(),
    })
    .eq('check_file_id', checkFileId)
    .eq('work_date', formatDate(targetDate))
  if (error) throw error
}

export const getDailyWorkFilesByRange = async (
  checkFileId: DailyWorkFile['check_file_id'],
  startDate: Dayjs,
  endDate: Dayjs,
  status?: DailyWorkFile['status'],
  limit?: number
) => {
  const query = useSupabaseClient()
    .from('daily_work_files')
    .select(
      '*, work_sheets!inner(*, work_items!inner(*, notes(*), tickets(*), work_number_conditions(*)))'
    )
    .eq('check_file_id', checkFileId)
    .gte('work_date', formatDate(startDate))
    .lte('work_date', formatDate(endDate))
    .order('work_date', { ascending: true })

  if (status) query.eq('status', status)
  if (limit) query.limit(limit)

  const { data, error } = await query
  if (error) throw error

  return data
}

// NOTE: useTodo のパフォーマンス改善のため、専用にクエリを発行するメソッド作成している
export const getWorkDateByRange = async (
  checkFileId: DailyWorkFile['check_file_id'],
  startDate: Dayjs,
  endDate: Dayjs,
  status: DailyWorkFile['status'],
  limit: number
) => {
  const query = useSupabaseClient()
    .from('daily_work_files')
    .select('work_date')
    .eq('check_file_id', checkFileId)
    .gte('work_date', formatDate(startDate))
    .lte('work_date', formatDate(endDate))
    .eq('status', status)
    .order('work_date', { ascending: true })
    .limit(limit)

  const { data, error } = await query
  if (error) throw error

  return data
}

export const getSubmittedDailyWorkFilesByScheduleDates = async (
  checkFileId: DailyWorkFile['check_file_id'],
  dates: string[]
) => {
  const query = useSupabaseClient()
    .from('daily_work_files')
    .select('*')
    .eq('check_file_id', checkFileId)
    .in('work_date', dates)
    .eq('status', 'SUBMITTED')

  const { data, error } = await query
  if (error) throw error
  return data
}
